@import '../../../bps/components/teaserGroupTeaser/teaserGroupTeaser.scss';

.teasergroup_teaser {
  $root: &;

  &--float {
    #{$root}__item {
      @media (max-width: $screen-tablet-xsm) {
        width: 100%;
      }
    }
  }

  &--coverflow {
    @media (max-width: $screen-tablet-xsm) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.newsletter-teaser {
  $root: &;
  position: relative;

  &__step-1 {
    color: $whiteColor;
    padding: 16px 10px 8px;
    background-position: 75%;

    @media (min-width: $screen-tablet-landscape) {
      padding: 24px 24px 16px;
    }

    #{$root}__row {
      &--head {
        max-width: 60%;
      }

      @media (min-width: $screen-tablet-sm) {
        max-width: 75%;
      }

      @media (min-width: $screen-tablet-landscape) {
        max-width: 60%;
      }

      &--footer {
        padding: 0;
      }
    }
  }

  &__row {
    padding: 8px 0;

    h2,
    h3 {
      margin: 0 0 15px;
      font-weight: $headlineH2FontWeight;
    }

    p {
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }

    a {
      color: currentColor !important;
    }
  }

  &__headline {
    @include fontSize($fontSizeXXXLarge);
    font-weight: $fontWeightBold;
    line-height: 1.2;
    width: 100%;
  }

  &__text {
    display: none;

    @media (min-width: $screen-tablet-sm) {
      display: block;
      font-size: $fontSizeXMedium;
      line-height: 1.1;
    }
  }

  &__form {
    margin-top: 15px;
  }

  &__flag {
    $sizeM: 110px;
    $sizeD: 150px;
    width: $sizeM;
    height: $sizeM;
    right: 24px;
    top: 8px;
    position: absolute;
    border-radius: 50%;
    background: #7e5d56;
    color: $whiteColor;
    border: currentColor 6px solid;
    text-align: center;
    font-weight: $fontWeightBold;
    font-size: 35px;
    line-height: .5;
    padding-top: 31px;

    span {
      font-size: 45%;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: $sizeD;
      height: $sizeD;
      font-size: 50px;
      padding-top: 40px;
    }

    @media (min-width: $screen-laptop) {
      right: 5%;
      top: 8%;
    }
  }

  &__success {
    background: $whiteColor;
    padding: 8px;

    .newsletter-form__declaration,
    #{$root}__headline {
      text-align: center;
    }
  }

  .newsletter-form {
    &__declaration {
      font-size: $fontSizeXSmall !important;
      font-weight: $fontWeightThin;
      margin-top: -8px;
      display: block;
    }
  }

  .radio-input__label {
    color: currentColor;
  }

  &__next-steps {
    @include clearList();
    margin: 0;

    @media (min-width: $screen-tablet-sm) {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: start;
      margin: 0 auto 10px;
    }

    .icon::before {
      color: $brandPrimaryColor;
      font-size: 50px;
    }

    .icon--confirmationArrow::before {
      font-size: 12px;
      color: $grayDarkColor;
    }
  }

  &__next-step {
    font-size: $fontSizeXSmall;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
    width: 100%;

    @media (min-width: $screen-tablet-sm) {
      font-size: $fontSizeSmall;
      max-width: 200px;
      padding: 0 15px;
      margin-bottom: 15px;
      flex-basis: 30%;
    }

    &:first-child {
      padding-left: 0;
    }

    &--link {
      transform: rotate(90deg);

      @media (min-width: $screen-tablet-sm) {
        transform: none;
      }
    }
  }

  &__icon-text {
    margin: 8px auto 0;
    max-width: 158px;

    @media (min-width: $screen-tablet-sm) {
      max-width: inherit;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
newsletter-teaser {
  .loader {
    top: 56px;
  }

  .newsletter-teaser__flag,
  .newsletter-teaser__success {
    display: none;
  }
}

@mixin teaserContainer {
  display: flex;
  flex-flow: column;
}

@mixin teaserHeadline {
  @include fontSize($fontSizeXXXLarge);
  font-weight: $fontWeightBold;
  order: 1;
  color: $blackColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.2;
  margin: 0 0 12px;

  @media (min-width: $screen-tablet-portrait) {
    margin: 0 0 28px;
  }
}

@mixin teaserLink {
  flex-grow: 1;
  order: 3;
  text-align: center;
  max-width: 100%;
  margin-top: 20px;

  @media (min-width: $screen-tablet-portrait) {
    padding: 0;
    width: 100%;
  }
}

@mixin teaserItemsContainer {
  width: auto;
  order: 2;
  position: relative;

  .tabs-component-panel & {
    margin: 0;
  }
}
